export const HomeData = [
  "Passing State and Props",
  "Handling Event from child to parent  ",
  "Simple Tab",
  "Simple Accordian",
  "Conditional JSX",
  "React Router DOM",
  "<Navlink> with active class",
  "useState() Hook",
  "useEffect() Hook",
  "useContext() Hook ",
  "useReducer() Hook ",
  "useNavigate() Hook from react router dom ",
  "useParams() Hook from react router dom ",
  "Absolute past(with/) v/s Relative Path without /",
  "useLoaderData from React router dom to fetch data from API in route itself.",
  "useNavigation Hook, navigation.state return idle, loading, submiting value.",
  "useMemo() Hook ",
  "Fetch Api call",
  "Working with .filter method",
  "Working with .includes method",
  "Working with .forEach loop",
  "Product Listing with Filter",
  "Blog search by title",
  "Tailwind CSS class ",
  "Pagination Component",
  "Breadcrumb Component",
  "Dynamic Page Title for each Page",
  "React Memo  ",
];
